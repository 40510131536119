// import classNames from 'classnames';
import { Trans } from '@lingui/macro';
import React, { PureComponent } from 'react';

// import CustomScrollBarContainer from '@geobank/components/src/forms/CustomScrollbarContainer/CustomScrollBarContainer';
import Button, { ButtonColor } from '@geobank/components/src/common/Button/Button';

import styles from './LeftMenu.css';

import { RootState } from 'ducks';
import {
  changeSearchParams,
  clearMetadata,
  getIsFetching,
  getMetadata,
  getSearchParams,
  searchImages,
} from 'ducks/metadata';
import { connect } from 'react-redux';
// import { drawControl, getRegionsOfInterest, getIsMainPanelShow } from 'ducks/map';
// import { fetchPlatforms } from 'ducks/classifier';
// import { getIsAuthenticated, getIFetching as getIsAuthenticating, iamActions } from 'ducks/authIAM';

// import { camelToSnake } from '@geobank/assets/src/uibook/helpers/utils';
// import dateFormat from 'date-fns/format';
// import SearchForm from './SearchForm/SearchForm';
import Loader from '@geobank/components/src/common/Loader/Loader';
import {
  canOrderImageByInstrument,
  canOrderOperativeImageAndGetDiffByDate,
} from 'components/utils/order';
import { drawControl, getGeofenceGeometry, getRegionsOfInterest } from 'ducks/map';
import { notifyActions } from 'ducks/message';
import {
  CartImage,
  fetchImagesQuotation,
  updateCurrentCartImages,
  updateCurrentCartParams,
} from 'ducks/order'; // CartImage
import { ImageMetadata, ImageMetadataDetail } from 'ducks/types/metadataTypes';
import OrderImageForm from './SearchResult/OrderImage/OrderImageForm';
import OrderImagesForm from './SearchResult/OrderImage/OrderImagesForm';
// import WKT from 'ol/format/WKT';
// import GeoJSON from 'ol/format/GeoJSON';
// import GeoportalMap from 'components/map/Map/Map';
import SearchResult, { SearchResult as SearchResultFree } from './SearchResult/SearchResult';

const mapStateToProps = (state: RootState) => ({
  isFetching: getIsFetching(state),
  searchParams: getSearchParams(state),
  metadata: getMetadata(state),
  regionsOfInterest: getRegionsOfInterest(state),
  geofenceGeometry: getGeofenceGeometry(state),
});
const mapDispatchToProps = {
  searchImagesRequest: searchImages.request,
  clearSearchParams: searchImages.clearParams,
  updateCurrentCartImagesRequest: updateCurrentCartImages.request,
  updateCartParams: updateCurrentCartParams.request,
  pushMessage: notifyActions.push,
  fetchImagesQuotationRequest: fetchImagesQuotation.request,
  clearMetadata: clearMetadata,
  drawControlShow: drawControl.show,
  changeSearchParams: changeSearchParams,
};

// type SearchFormPanelProps = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

// interface SearchFormPanelProps {
//   onPanelChange: (panelName: 'params' | 'results') => void;
// }

interface SearchFormPanelState {
  isOrderImagePanelOpen?: boolean; // isOrderImageShow
  orderImages?: ImageMetadata | ImageMetadata[]; // | string[] // string | CartImage
}

class SearchFormPanel extends PureComponent<
  ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & SearchFormPanelState
> {
  public state: SearchFormPanelState = {
    isOrderImagePanelOpen: false,
    orderImages: undefined,
  };

  public searchResultRef: React.RefObject<SearchResultFree>; // typeof SearchResult - connected component
  public orderImageFormRef: React.RefObject<any>; // React.RefObject<OrderImageForm>;

  constructor(props: any) {
    super(props);
    this.searchResultRef = React.createRef();
    this.orderImageFormRef = React.createRef();
  }

  public handlePanelChange = () => {
    const ids = this.props.metadata.map(item => item.identifier);
    // this.props.onPanelChange(panelName);
    this.props.clearMetadata(ids);
    this.props.changeSearchParams({ offset: 0 });
    // if (this.props.regionsOfInterest.length !== 0) {
    //   this.props.drawControlShow();
    // }
  };

  public handleShowOrderImageForm = (image?: ImageMetadata) => {
    let orderImages;
    if (image !== undefined) {
      orderImages = image;
      // TODO - если один снимок - верни метаданные! иначе, нужно только количество - массив из ids
    } else {
      const checkedImages = this.searchResultRef.current
        ?.getCheckedImages()
        .filter(
          item =>
            canOrderImageByInstrument(item.instrumentIdentifier, item.platformIdentifier) &&
            canOrderOperativeImageAndGetDiffByDate(
              item.acquisitionDateBegin || item.acquisitionDateInstant
            )[0]
        );
      if (Array.isArray(checkedImages) && checkedImages.length > 0) {
        orderImages = checkedImages;
      }
    }

    if (orderImages) {
      this.setState({ isOrderImagePanelOpen: true, orderImages: orderImages });
      // this.props.updateCurrentCartImagesRequest(orderImages, 'add');
    } else {
      // message
      this.props.pushMessage({
        message: <span>Выберите хотя бы один снимок, доступный для заказа</span>,
        color: 'alert',
        timeout: 4000,
      });
    }
  };

  public handleCancelOrderImage = () => {
    this.setState({ isOrderImagePanelOpen: false, orderImages: undefined });
  };

  public handleAddToCart = (cartImages: CartImage | CartImage[]) => {
    cartImages = Array.isArray(cartImages) ? cartImages : [cartImages];
    if (cartImages.length === 0) {
      return;
    }
    this.props.updateCurrentCartImagesRequest(cartImages, 'add'); // additional options, from state;
    // (?) callback
    this.setState({ isOrderImagePanelOpen: false, orderImages: undefined });

    /* const optionsState = this.orderImageFormRef.current?.state;
    const orderImages = this.state.orderImages;
    if (optionsState && orderImages) {
      const cartImages = (Array.isArray(orderImages) ? orderImages : [orderImages]).map(md => ({
        metadataIdentifier: md.identifier,
        croppingPolygon: md.geometry,
        price: 0,
      }));

      this.props.updateCurrentCartImagesRequest(cartImages, 'add'); // additional options, from state;
      // (?) callback
      this.setState({ isOrderImagePanelOpen: false, orderImages: undefined });
    } else {
      // TODO - push message: Отметьте галочкой нужные снимки и повторите действие
      // или помечать крестом кнопку, если выбрано 0, но это потребует разместить кнопки в форме
    } */
  };

  public getOrderImageForm = (orderImages: ImageMetadata | ImageMetadata[]) => {
    if (Array.isArray(orderImages)) {
      return (
        <OrderImagesForm
          ref={this.orderImageFormRef}
          isGeofenced={this.props.geofenceGeometry.length > 0}
          orderImages={orderImages}
          regionsOfInterest={this.props.regionsOfInterest}
          onCancel={this.handleCancelOrderImage}
          onAddToCart={this.handleAddToCart}
          getPrice={this.props.fetchImagesQuotationRequest}
        />
      );
    } else {
      // return null;
      return (
        <OrderImageForm
          ref={this.orderImageFormRef}
          isGeofenced={this.props.geofenceGeometry.length > 0}
          orderImage={orderImages}
          regionsOfInterest={this.props.regionsOfInterest}
          onUpdateCartParams={this.props.updateCartParams}
          onCancel={this.handleCancelOrderImage}
          onAddToCart={this.handleAddToCart}
          getPrice={this.props.fetchImagesQuotationRequest}
        />
      );
    }
  };

  public render() {
    const { isOrderImagePanelOpen, orderImages } = this.state;
    const { isFetching, metadata, searchParams } = this.props;

    return (
      <>
        {/* onCancle={this.handleCancelOrderImage} */}
        {isOrderImagePanelOpen && orderImages !== undefined && (
          <div style={{ position: 'absolute', left: '430px' }}>
            {this.getOrderImageForm(orderImages)}
          </div>
        )}

        {isOrderImagePanelOpen && <div className={styles.searchPanelMask} />}

        <SearchResult
          // @ts-ignore
          ref={this.searchResultRef}
          searchParams={searchParams}
          results={metadata as ImageMetadataDetail[]}
          onBack={() => this.handlePanelChange()}
          onAddToCartImage={this.handleShowOrderImageForm}
        />

        {/* TODO - переместить кнопки в SearchResult. И можно убрать connect с компонента! */}
        <div className={styles.div_search_option__search}>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Button
              color={ButtonColor.WHITE}
              width="133px"
              onClick={() => this.handlePanelChange()}
            >
              <Trans>Редактировать параметры</Trans>
            </Button>
            {isFetching && <Loader />}
            <Button
              width="133px"
              onClick={() => this.handleShowOrderImageForm()}
              // disabled
              // title="в разработке..."
            >
              <Trans>Добавить в корзину</Trans>
            </Button>
          </div>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchFormPanel);
