// import TileLayer from 'ol/layer/Tile';
import TileLayer from 'libs/ol/layer/Tile'; // , { TileLayerOptions }
import XYZ from 'ol/source/XYZ';

import { CreateLayerBundle } from '../types';
const createSTACItemXYZLayer = (bundle: CreateLayerBundle) => {
  const { id, name, options } = bundle;

  const source = new XYZ({
    url: options.url,
    urls: options.urls,
    transition: 0,
    attributions: options.attribution,
    maxZoom: options.maxZoom,
    tileSize: options.tileSize,
  });
  const layer = new TileLayer({
    id,
    name,
    extent: options.extent,
    preload: Infinity,
    // -- /custom args delimiter --
    visible: options.visible || options.visibility || false,
    source: source,
    // minResolution: 4000,
    zIndex: options.zIndex,
  });
  return layer;
};

export default createSTACItemXYZLayer;
