export interface IResolutionToInstruments {
  [key: string]: string[];
}

export const resolutionToInstruments: IResolutionToInstruments = {
  // not sorted: -
  low: [], // Сверхнизкое;
  medium_low: ['MSUIKSRM', 'MSUMR'], // Низкое; MSUMR (пока недоступен для заказа), был Метеор 'MSUTM101,MSUTM102' (+ 'MSU101,MSU102')
  medium: [
    'SVR',
    'SSR',
    'GSA',
    'OLITIRS',
    'MSI',
    'MSUTM101,MSUTM102',
    'LISS3',
    'LISS4',
    'MUX',
    'MSU101,MSU102',
    'WFI',
    'IRS',
    'AWiFS',
  ], // Среднее; KV MSS, иностранцы (их надо специально выбирать!)
  high: ['MSS', 'AVR', 'MUL12U-R', 'PANMUX', 'WFV', 'PMS', 'BWD', 'MUXNAD'], // Высокое; KV PSS, GTNL1 МСС
  detailed: ['GTNL1', 'GTN'], // Сверхвысокое; GTNL1 ПСС
};
