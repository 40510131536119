// from ProductParametersForm
// OutputProductParameters, ProductParametersForm
import { Plural, Trans } from '@lingui/macro';
// import { i18nMark } from '@lingui/react';
import classNames from 'classnames';
// import { connect } from 'react-redux';
import get from 'lodash/get';
import { GeoJSONFeature } from 'ol/format/GeoJSON';
import React, { PureComponent } from 'react';
import Tooltip from 'react-tooltip-lite';
// import { RootState } from 'ducks';
// import { updateCurrentCartParams, getCurrentCartParams } from 'ducks/order';

import Button, { ButtonColor } from '@geobank/components/src/common/Button/Button';
import CheckBox from 'components/common/forms/CheckBox/CheckBox';
// import CheckBoxGroupOld from 'components/forms/CheckBoxGroup';
import CheckBoxGroup from 'components/common/forms/CheckBox/CheckBoxGroup';
import { CheckBoxChangeEvent } from 'components/common/forms/CheckBox/interface';
import { RadioChangeEvent } from 'components/common/forms/Radio/interface';
import Radio from 'components/common/forms/Radio/Radio';
import RadioGroup from 'components/common/forms/Radio/RadioGroup';
import HelpIcon from 'components/common/HelpIcon/HelpIcon';

import { geoJSONToWKTAndTransform } from 'components/map/Map/utils';
import { formatCurrency } from 'components/utils/format';
import { ImageMetadata } from 'ducks/metadata';
import { CartImage, ProductPMS, QuotationRequestParams } from 'ducks/order';
// import API from 'api';

import CrossIcon from '@geobank/assets/src/icons/CrossIcon';
import IconButton from '@geobank/components/src/common/IconButton/IconButton';
import ConflictList from './ConflictImage/ConflictList';
import * as productOptions from './productOptions';
import { PreparedImage } from './types'; // ProductPMS
import {
  isAdditionalOptionsAvailable,
  isGeometryCroppingAvailable,
  isPanhsarpSelectAvailable,
} from './utils';

import styles from './OrderImageForm.module.scss';

interface OrderImagesFormProps {
  // data: { [key: string]: string | number };
  // onChange: (data: any) => void;
  // instrument: string;
  orderImages: ImageMetadata[]; // ImageMetadata | ImageMetadata[];
  regionsOfInterest: GeoJSONFeature[];
  isGeofenced: boolean;
  onCancel: () => void;
  onAddToCart: (cartImages: CartImage[]) => void;
  getPrice: (data: QuotationRequestParams) => void;
}

interface OrderImagesState {
  // channelsUnion: 'union' | 'separate' | 'both';
  additionalOptions: { [key: string]: boolean };
  geometryCropping: 'region' | 'whole'; // | 'fragment'

  totalPrice?: number;

  availableProducts: Set<ProductPMS>;
  productPMS: ProductPMS;
  preparedImages: PreparedImage[];
  conflictedImages: PreparedImage[];

  isConflictPanelShow: boolean;
  // orderImages: ImageMetadata[]; // TODO - use in state to remove items!!!
}

// const mapStateToProps = (state: RootState) => ({
//   params: getCurrentCartParams(state),
// });
// const mapDispatchToProps = {
//   updateCurrentCartParamsRequest: updateCurrentCartParams.request,
// };

class OrderImagesForm extends PureComponent<
  // ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & OrderImagesFormProps
  OrderImagesFormProps,
  OrderImagesState
> {
  public state: OrderImagesState = {
    // channelsUnion: 'union',
    additionalOptions: { elevation: true },
    geometryCropping: 'whole',

    availableProducts: new Set<ProductPMS>(),
    productPMS: 'pansharp',
    preparedImages: [],
    conflictedImages: [],

    isConflictPanelShow: false,
    // orderImages: [],
  };

  // private handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  //   const fieldName = event.target.name;
  //   const value = event.target.value;
  //   // @ts-ignore
  //   // this.props.updateCurrentCartParamsRequest({ [fieldName]: value });

  //   this.props.onChange({ ...this.props.data, [fieldName]: value });
  // };

  // handleRadioInputChange = (fieldName: string, value: string) => {
  //   // @ts-ignore
  //   this.setState({ [fieldName]: value });
  // };

  // productPMS
  public handleProductInputChange = (event: RadioChangeEvent) => {
    const { name: fieldName, value } = event.target;
    // @ts-ignore
    this.setState({ [fieldName]: value });
  };

  public handleRadioInputChange = (event: RadioChangeEvent) => {
    const { name: fieldName, value } = event.target;
    // @ts-ignore
    this.setState({ [fieldName]: value });
  };

  public handleCheckBoxInputChangeOld = (fieldName: string, value: string) => {
    this.setState(prevState => {
      // @ts-ignore
      const newValue: string[] = [...prevState[fieldName]];
      const index = newValue.indexOf(value);
      if (index > -1) {
        newValue.splice(index, 1);
      } else {
        newValue.push(value);
      }

      return { ...prevState, [fieldName]: newValue };
    });
  };

  public handleCheckBoxInputChange = (e: CheckBoxChangeEvent) => {
    const { name: fieldName, value, checked } = e.target;
    // @ts-ignore
    this.setState({ [fieldName]: { ...this.state[fieldName], [value]: checked } });
  };

  // getBytesPerPixelOptions = () => {};

  public updatePrice() {
    const { orderImages, regionsOfInterest } = this.props; // regionsOfInterest
    const { additionalOptions, geometryCropping, productPMS } = this.state;

    const params: QuotationRequestParams = {
      bandCombination: productPMS, // band_combination,
      elevation: get(additionalOptions, 'elevation', false),
      // pansharpening: get(additionalOptions, 'pan-sharpening', false),
      images: orderImages.map(item => ({ metadataIdentifier: item.identifier })),
      callback: result => {
        this.setState({ totalPrice: result.totalPrice });
      },
    };
    if (regionsOfInterest.length > 0 && geometryCropping === 'region') {
      params.croppingPolygon = geoJSONToWKTAndTransform(regionsOfInterest[0]);
    }
    // else if (geometryCropping === 'region' && regionsOfInterest.length > 0) {
    //   params.cropping_polygon = geoJSONToWKTAndTransform(regionsOfInterest[0]);
    // }

    this.props.getPrice(params);
    ///
    // подумать, как бы прогонять запрос через сагу, чтобы было обновление сессии при её протухании
    /* API.getPrice(params)
      .then(resp => resp.data)
      .then(data => this.setState({ totalPrice: data.total_price }))
      .catch(err => console.log(err)); */
  }

  public handleCancel = () => {
    this.props.onCancel();
  };

  public handleAddToCart = () => {
    const { regionsOfInterest } = this.props; // orderImages
    const { /* additionalOptions, */ geometryCropping, preparedImages, productPMS } = this.state; // totalPrice

    // const cartImages: CartImage[] = orderImages.map(item => ({
    const cartImages: CartImage[] = preparedImages.map(item => ({
      // elevation: item.identifier.startsWith('ETRIS.') && get(additionalOptions, 'elevation', false), // no BBP.
      // pansharpening: get(additionalOptions, 'pan-sharpening', false),
      bandCombination: productPMS,
      metadataIdentifier: item.identifier,
      croppingPolygon:
        regionsOfInterest.length > 0 && geometryCropping === 'region'
          ? geoJSONToWKTAndTransform(regionsOfInterest[0])
          : undefined,
      price: 0,
    }));
    this.props.onAddToCart(cartImages);
  };

  public handleShowConflicts = () => {
    // pass

    this.setState({ isConflictPanelShow: true });
  };

  public handleCloseConflicts = () => {
    this.setState({ isConflictPanelShow: false });
  };

  public handleRemoveImages = (prImage: PreparedImage | PreparedImage[]) => {
    const { productPMS, preparedImages } = this.state; // orderImages
    const prImagesRemove = Array.isArray(prImage) ? prImage : [prImage];

    const result = [...preparedImages];
    prImagesRemove.forEach(prIm => {
      const imageIndex = result.indexOf(prIm);
      if (imageIndex > -1) {
        result.splice(imageIndex, 1);
      }
    });
    // return result;
    // const { productPMS, preparedImages } = this.state;
    const conflictedImages = this.getConflictedImages(productPMS, result);
    this.setState({ preparedImages: result, conflictedImages: conflictedImages });
    // this.setState({ orderImages: result });
  };

  public getConflictedImages = (productPMS: ProductPMS, preparedImages: PreparedImage[]) => {
    // default
    // ({ productPMS, preparedImages } = this.state);

    const withProblem: any = [];
    switch (productPMS) {
      case 'pansharp':
      case 'bundle':
        preparedImages.forEach((item: any) =>
          item.hasPanchromaticSensor && item.hasMultiSpectrSensor ? null : withProblem.push(item)
        );
        break;
      case 'ms':
        preparedImages.forEach((item: any) =>
          item.hasMultiSpectrSensor ? null : withProblem.push(item)
        );
        break;
      case 'pan':
        preparedImages.forEach((item: any) =>
          item.hasPanchromaticSensor ? null : withProblem.push(item)
        );
        break;
    }
    return withProblem;
  };

  public componentDidMount() {
    const { regionsOfInterest, orderImages } = this.props;
    let geometryCropping: 'region' | 'whole' = this.state.geometryCropping; // 'whole';

    if (regionsOfInterest.length > 0) {
      geometryCropping = 'region';
      /* this.setState({ geometryCropping: 'region' }, () => {
        // update the price will be in the componentDidUpdate on geometry change
        // this.updatePrice();
      }); */
    } else {
      // обновим при установке productPMS или изменении geometryCropping
      // this.updatePrice();
    }

    ////////////
    // console.log(this.props.orderImages.map(qq => qq.identifier));
    const firstImage = orderImages[0];
    const hasPanchromaticSensor =
      firstImage.sensors.find(sensor => sensor.isPanchromatic) !== undefined;
    const hasMultiSpectrSensor =
      firstImage.sensors.find(sensor => sensor.isPanchromatic === false) !== undefined;

    // default value
    let productPMS: ProductPMS = this.state.productPMS; // 'pansharp';
    if (
      hasPanchromaticSensor &&
      hasMultiSpectrSensor &&
      isPanhsarpSelectAvailable(this.props.orderImages)
    ) {
      productPMS = 'pansharp';
    } else if (hasMultiSpectrSensor) {
      productPMS = 'ms';
    } else if (hasPanchromaticSensor) {
      productPMS = 'pan';
    }

    // тут в цикле можно изменить флаги hasPanchromaticSensor, hasMultiSpectrSensor
    const preparedImages = orderImages.map((md, index) => {
      const hasPAN = md.sensors.find(sensor => sensor.isPanchromatic) !== undefined;
      const hasMS = md.sensors.find(sensor => sensor.isPanchromatic === false) !== undefined;

      return {
        // index: index,
        identifier: md.identifier,
        hasPanchromaticSensor: hasPAN,
        hasMultiSpectrSensor: hasMS,
        // conflict / error: {} | []
        md: md,
      };
    });

    // all values
    const availableProducts = new Set<ProductPMS>();
    preparedImages.forEach(item => {
      if (
        item.hasPanchromaticSensor &&
        item.hasMultiSpectrSensor &&
        isPanhsarpSelectAvailable(item.md)
      ) {
        availableProducts.add('pansharp');
        availableProducts.add('bundle');
      }
      if (item.hasMultiSpectrSensor) {
        availableProducts.add('ms');
      }
      if (item.hasPanchromaticSensor) {
        availableProducts.add('pan');
      }
    });

    // calc problems/conflicts
    const conflictedImages = this.getConflictedImages(productPMS, preparedImages);

    if (this.state.productPMS === productPMS && this.state.geometryCropping === geometryCropping) {
      this.updatePrice();
    }
    this.setState(
      {
        geometryCropping,
        availableProducts,
        productPMS,
        preparedImages,
        conflictedImages,
        // orderImages
      }
      // , () => {}
    );
    //////////
  }

  public componentDidUpdate(prevProps: OrderImagesFormProps, prevState: OrderImagesState) {
    if (
      prevState.additionalOptions !== this.state.additionalOptions ||
      prevState.geometryCropping !== this.state.geometryCropping ||
      prevState.productPMS !== this.state.productPMS
    ) {
      this.updatePrice();
    }

    if (prevState.productPMS !== this.state.productPMS) {
      const { productPMS, preparedImages } = this.state;
      const conflictedImages = this.getConflictedImages(productPMS, preparedImages);
      this.setState({ conflictedImages: conflictedImages });

      // const availableProducts = {
      //   pansharp: [],
      //   bundle: [],
      // };

      // console.log(
      //   this.state.productPMS,
      //   this.props.orderImages.map(item => item.sensors)
      // );
    }
  }

  public render() {
    const {
      /* channelsUnion, */
      additionalOptions,
      geometryCropping,
      totalPrice,
      availableProducts,
      productPMS,
      // preparedImages,
      conflictedImages,
      isConflictPanelShow,
      // orderImages,
    } = this.state;
    const { orderImages } = this.props;

    // const conflicts = this.getConflictedImages(preparedImages);
    // const hasConflicts = conflicts.length > 0;
    const conflicts = conflictedImages.length; // count

    if (isConflictPanelShow === true) {
      return (
        <div className={styles.root} style={{ padding: 0 }}>
          <div
            className={classNames(styles.headerContainer, styles.marginBottom)}
            style={{ padding: '20px 20px 0' }}
          >
            <div className={classNames(styles.header)} style={{ fontFamily: 'inherit' }}>
              <Trans id="order_images_conflicts.header_text">
                <Plural value={conflicts} one="Этот" few="Эти" many="Эти" other="Эти" /> {conflicts}{' '}
                <Plural
                  value={conflicts}
                  one="снимок"
                  few="снимка"
                  many="снимков"
                  other="снимков"
                />{' '}
                <span style={{ color: 'red' }}>не соответствуют</span> заданным параметрам выдачи
              </Trans>
            </div>
            {/* w: 32px, h: 32px, flex-shrink: 0 --> тогда размер кнопки будет скукоживаться! (см. dev tools) */}
            <IconButton onClick={this.handleCloseConflicts}>
              <CrossIcon />
            </IconButton>
          </div>

          <div className={styles.body2}>
            <ConflictList
              // orderImages={orderImages}
              conflictedImages={conflictedImages}
              onRemove={this.handleRemoveImages}
            />
          </div>

          <div className={classNames(styles.actionsContainer)} style={{ padding: '0 20px 20px' }}>
            <Button color={ButtonColor.WHITE} width={'150px'} onClick={this.handleCloseConflicts}>
              <Trans>Изменить параметры выдачи</Trans>
            </Button>
            {conflicts > 0 ? (
              <Button
                color={ButtonColor.DEFAULT}
                width={'150px'}
                onClick={this.handleAddToCart}
                // disabled
              >
                <Trans>Всё равно добавить в корзину</Trans>
              </Button>
            ) : (
              <Button color={ButtonColor.DEFAULT} width={'150px'} onClick={this.handleAddToCart}>
                <Trans>Добавить в корзину</Trans>
              </Button>
            )}
          </div>
        </div>
      );
    }

    return (
      <div className={styles.root}>
        {/* <div className={classNames(styles.header, styles.marginBottom)}>
          {/* {Array.isArray(orderImages) ? (
            <Trans>Поместить {orderImages.length} снимков в корзину</Trans>
          ) : (
            <Trans>Поместить снимок в корзину</Trans>
          )} * /}
          <Trans id="order_images.header_text">
            Поместить {orderImages.length}
            <Plural
              value={orderImages.length}
              one="снимок"
              few="снимка"
              many="снимков"
              other="снимков"
            />
            в корзину
          </Trans>
        </div> */}
        <div className={classNames(styles.headerContainer, styles.marginBottom)}>
          <h3 className={styles.header2}>
            <Trans id="order_images.header_text">
              Поместить {orderImages.length}
              <Plural
                value={orderImages.length}
                one="снимок"
                few="снимка"
                many="снимков"
                other="снимков"
              />
              в корзину
            </Trans>
          </h3>
          <div className={styles.stepLabel}>
            <Trans>Шаг 1 из 2</Trans>
          </div>
        </div>

        {/* <div className="paramContainer content marginBottom"> */}
        <div>
          <div className={styles.paramTitle}>
            <span>
              <Trans id="order_images.title_additional_params">
                Параметры выдачи каждого снимка
              </Trans>
            </span>
          </div>
          <div className={styles.paramField}>
            {/* test */}
            <RadioGroup
              name="productPMS"
              value={productPMS}
              onChange={this.handleProductInputChange}
            >
              {availableProducts.has('pansharp') && (
                <Radio value="pansharp">{productOptions.pansharpTitle}</Radio>
              )}
              {availableProducts.has('bundle') && (
                <Radio value="bundle">{productOptions.bundleTitle}</Radio>
              )}
              {availableProducts.has('ms') && <Radio value="ms">{productOptions.msTitle}</Radio>}
              {/* Мультиспектральные каналы */}
              {/* --- end --- */}
              {availableProducts.has('pan') && <Radio value="pan">{productOptions.panTitle}</Radio>}
            </RadioGroup>
          </div>

          {false && isAdditionalOptionsAvailable(orderImages) && (
            <div className={styles.paramField}>
              <CheckBoxGroup
                name="additionalOptions"
                value={additionalOptions}
                onChange={this.handleCheckBoxInputChange}
              >
                {/* <CheckBox value="pan-sharpening" multiline>
                <Trans id="order_image.value_pan-sharpening">
                  Сделать паншарп-изображение <br />
                  (в отдельном TIFF-файле)
                </Trans>
              </CheckBox> */}
                <CheckBox value="elevation" /* value="no-elevation" */>
                  {/* Ортотрансформировать <br />с использованием ЦМР */}
                  <Trans id="order_image.value_elevation">Использовать ЦМР</Trans>
                  <Tooltip
                    tagName="span"
                    styles={{ marginLeft: '4px' }}
                    tipContentClassName="tip-content--border-grey"
                    arrow={false}
                    background="white"
                    color="black"
                    direction="right"
                    content={
                      <Trans id="order_image.hint_elevation">
                        Применять цифровую модель рельефа
                      </Trans>
                    }
                  >
                    <HelpIcon />
                  </Tooltip>
                </CheckBox>
              </CheckBoxGroup>
            </div>
          )}
        </div>

        <div className={styles.delimeter} />

        <div>
          <div className={styles.paramTitle}>
            <span>
              {/* <Trans>Параметры выдачи всех снимков</Trans>; id="order_image.title_images_geometry" */}
              <Trans id="order_images.title_geometry">Геометрия снимков</Trans>
            </span>
          </div>
          {isGeometryCroppingAvailable(orderImages) ? (
            <div className={styles.paramField}>
              <RadioGroup
                name="geometryCropping"
                value={geometryCropping}
                onChange={this.handleRadioInputChange}
              >
                <Radio value="region" disabled={this.props.regionsOfInterest.length === 0}>
                  <Trans id="order_image.value_geometry_region">Обрезать по району интереса</Trans>
                  {this.props.regionsOfInterest.length === 0 && (
                    <Tooltip
                      tagName="span"
                      styles={{ marginLeft: '4px' }}
                      tipContentClassName="tip-content--border-grey"
                      arrow={false}
                      background="white"
                      color="black"
                      direction="right"
                      content={
                        <Trans id="order_image.hint_geometry_region">
                          Требуется указать геометрию при поиске
                        </Trans>
                      }
                    >
                      <HelpIcon />
                    </Tooltip>
                  )}
                </Radio>
                {!this.props.isGeofenced && (
                  <Radio value="whole">
                    <Trans id="order_images.value_geometry_whole">Добавить снимки целиком</Trans>
                  </Radio>
                )}
                {/* <Radio value="fragment">Вырезать фрагмент</Radio> */}
              </RadioGroup>
            </div>
          ) : (
            <div className={styles.paramField}>
              <span style={{ color: 'grey', fontSize: '14px' }}>
                Снимки доступны для заказа только целиком
              </span>
            </div>
          )}
        </div>

        <div className={styles.delimeter} />

        <div style={{ marginBottom: '10px' }}>
          {totalPrice !== undefined ? (
            <div className={styles.priceContainer}>
              {/* Общая стоимость: <span>4 900р</span> */}
              <Trans id="order_images.text_cost">
                Общая стоимость: <span>{formatCurrency(totalPrice)}</span>
              </Trans>
            </div>
          ) : (
            <div className={styles.priceContainer}>
              <Trans id="order_image.text_cost_pending">Идёт расчёт стоимости ...</Trans>
            </div>
          )}
        </div>

        {conflicts > 0 ? (
          <div style={{ marginBottom: '10px' }}>
            <span style={{ color: 'red' }}>
              {conflicts} снимков не соответствуют заданным параметрам выдачи
            </span>
            <br />
            {/* Попробуйте изменить параметры выдачи или удалить неподходящие снимки, нажав "Просмотр конфликтов" */}
            <span style={{ color: 'grey', fontSize: '14px' }}>
              (Попробуйте изменить параметры выдачи или удалить снимки, нажав "Просмотр конфликтов")
            </span>
          </div>
        ) : (
          <div style={{ marginBottom: '10px' }}>
            <span style={{ color: 'grey', fontSize: '14px' }}>
              Если хотите заказать только определённые спектральные каналы и/или произвольно
              вырезанные фрагменты снимков, нажмите "Отменить" и воспользуйтесь функцией добавления
              снимков по отдельности
            </span>
          </div>
        )}

        <div className={classNames(styles.actionsContainer)}>
          <Button color={ButtonColor.WHITE} width={'150px'} onClick={this.handleCancel}>
            <Trans>Отменить</Trans>
          </Button>
          {conflicts > 0 ? (
            <Button
              color={ButtonColor.DEFAULT}
              width={'150px'}
              onClick={this.handleShowConflicts}
              // disabled
            >
              <Trans>Просмотр конфликтов</Trans>
            </Button>
          ) : (
            <Button color={ButtonColor.DEFAULT} width={'150px'} onClick={this.handleAddToCart}>
              <Trans>Добавить в корзину</Trans>
            </Button>
          )}
        </div>
      </div>
    );
  }
}

// export default connect(mapStateToProps, mapDispatchToProps)(OrderImagesForm);
export default OrderImagesForm;
