import { i18nMark } from '@lingui/react';
import { ActionType, getType } from 'deox'; // createActionCreator, createReducer
import { all, call, put, select, takeLatest } from 'redux-saga/effects'; // , put
import { clearItems, getActiveItemIndex, getItems, getStartTime } from './../ducks/stacItems';

import GeoportalMap, { LAYER_GROUPS } from 'components/map/Map/Map';
import { notifyActions } from 'ducks/message';
import {
  animateItems,
  changeActiveItemIndex,
  changeStartTime,
  // getItems,
  fetchDates,
  fetchItems,
  getAnimateItems,
} from 'ducks/stacItems';

import { getMosaicLayers, getSelectedMosaicLayerId } from 'ducks/mapLayers';

import * as stacItemsTypes from 'ducks/types/stacItemsTypes';
import { groupBy } from 'lodash';

import API from 'api';

import { VTMS_URL } from 'api/realAPI';
import { genId } from 'components/utils/identifier';
import { Debugger } from 'utils/logging';
import { getNestedObjectValue } from './map';

const debug = Debugger('MapSaga');

type MosaicLayers = ReturnType<typeof getMosaicLayers>;

interface CollectionParams {
  collection: string;
  instrument: string;
  processingLevelCode: string;
}

const getCollectionDate = (collectionId: string) => {
  const splittedId = collectionId.split('.');
  return splittedId.slice(-3).join('-');
};

const collectionToInstrumentMap: { [key: string]: CollectionParams[] } = {
  MSUGS: [
    {
      collection: 'roscosmos-opendata.EL_ARCM',
      instrument: 'MSU-GS',
      processingLevelCode: 'L3M',
    },
  ],
  MSUGS_BT9: [
    {
      collection: 'roscosmos-opendata.EL_ARCM',
      instrument: 'MSU-GS',
      processingLevelCode: 'L3BT9',
    },
  ],
  MSUGS_BT: [
    {
      collection: 'roscosmos-opendata.EL_ARCM',
      instrument: 'MSU-GS',
      processingLevelCode: 'L3BT',
    },
  ],
  MSUMR_D: [
    {
      collection: 'roscosmos-opendata.MM',
      instrument: 'MSU-MR',
      processingLevelCode: 'L3MD',
    },
  ],
  MSUMR_N: [
    {
      collection: 'roscosmos-opendata.MM',
      instrument: 'MSU-MR',
      processingLevelCode: 'L3MN',
    },
  ],
  MSUMR: [
    {
      collection: 'roscosmos-opendata.MM',
      instrument: 'MSU-MR',
      processingLevelCode: 'L3C',
    },
  ],
  MSUGS_A_EL: [
    {
      collection: 'roscosmos-opendata.Arctic-M',
      instrument: 'MSU-GS-A',
      processingLevelCode: 'L2',
    },
    {
      collection: 'roscosmos-opendata.Arctic-M',
      instrument: 'MSU-GS-A',
      processingLevelCode: ' L2_RGB_VIS_IR',
    },
    {
      collection: 'roscosmos-opendata.Electro-L',
      instrument: 'MSU-GS',
      processingLevelCode: 'L2',
    },
  ],
};

export const findNearestIndex = (targetTime: string, dates: Date[]) => {
  const targetDate =
    targetTime !== ''
      ? new Date(`${dates[0].toISOString().substring(0, 11)}${targetTime}`)
      : dates[0];
  const diffArray = dates.map(date => Math.abs(targetDate.getTime() - date.getTime()));
  const closestDateIndex = diffArray.indexOf(Math.min(...diffArray));
  return closestDateIndex;
};

export function* fetchItemsDatesFlow(action: ActionType<typeof fetchDates.request>) {
  const messageId = genId();
  const currentDate = action.payload ? action.payload : new Date();
  const selectedImageLayerId: string = yield select(getSelectedMosaicLayerId);
  const year = `${currentDate.getFullYear()}`;
  const month = `${currentDate.getMonth() + 1}`.padStart(2, '0');
  const collectionsParams = collectionToInstrumentMap[selectedImageLayerId]
    .map(col => `${col.collection}.${col.instrument}.${col.processingLevelCode}.${year}.${month}`)
    .join(',');

  try {
    yield put(
      notifyActions.push({
        timeout: -1,
        id: messageId,
        message: i18nMark(`Идёт поиск коллекций за ${month}.${year}...`),
        place: 'bc',
      })
    );
    const response: ReturnType<typeof API.getSTACDates> = yield call(
      API.getSTACDates,
      collectionsParams
    );
    // @ts-ignore
    const collections: any[] = response.data.collections;
    // const collectionDates = collections.map(collection => collection.title.replace(/\./g, '-'));
    const collectionDates = collections.map(collection => getCollectionDate(collection.id));
    yield put(
      fetchDates.success(collectionDates.sort((a: string, b: string) => ('' + a).localeCompare(b)))
    );
    const map = GeoportalMap.getInstance();

    if (collectionDates.length === 0) {
      yield call([map, 'removeMosaicLayers']);
      yield put(
        notifyActions.push({
          timeout: 4000,
          color: 'info',
          message: i18nMark(`Не найдено коллекций за ${month}.${year} !`),
          place: 'bc',
        })
      );
    } else {
      yield put(fetchItems.request(collectionDates[collectionDates.length - 1]));
    }
  } catch (error) {
    yield put(fetchDates.failure(error));
  } finally {
    yield put(notifyActions.remove(messageId));
  }
}

type STACItems = ReturnType<typeof API.getSTACItems>;

export function* fetchItemsFlow(action: ActionType<typeof fetchItems.request>) {
  const messageId = genId();
  const currentDate = action.payload ? new Date(action.payload) : new Date();
  const timezoneOffset = currentDate.getTimezoneOffset();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth();
  const day = currentDate.getDate();
  const startLocaleDate = new Date(year, month, day);
  const endLocaleDate = new Date(startLocaleDate);
  endLocaleDate.setMinutes(endLocaleDate.getMinutes() + 1440);
  const selectedImageLayerId: string = yield select(getSelectedMosaicLayerId);
  // const imageLayers: ImageLayers = yield select(getImageLayersById);
  const dateRange: string = `${startLocaleDate
    .toISOString()
    .slice(0, 19)}/${endLocaleDate.toISOString().slice(0, 19)}`;

  const collectionsParam = collectionToInstrumentMap[selectedImageLayerId]
    .map(col => `${col.collection}.${col.instrument}.${col.processingLevelCode}`)
    .join(',');
  const params = {
    dateRange: dateRange,
    collections: collectionsParam,
  };
  try {
    yield put(
      notifyActions.push({
        timeout: -1,
        id: messageId,
        message: i18nMark('Идёт поиск мозаик ...'),
        place: 'bc',
      })
    );
    const response: STACItems = yield call(API.getSTACItems, params);
    // @ts-ignore
    const features: stacItemsTypes.STACFeature[] = response.data.features;
    const groupedItems = groupBy(features, feature => {
      const localeDate = new Date(feature.properties.datetime);
      localeDate.setMinutes(localeDate.getMinutes() - timezoneOffset);
      return localeDate;
    });
    const items = Object.entries(groupedItems).map(([key, value]) => ({
      [key]: value.map(({ properties, assets }) => ({ properties, assets })),
    }));
    yield put(fetchItems.success(items));
    if (items.length === 0) {
      yield put(
        notifyActions.push({
          timeout: 4000,
          color: 'info',
          message: i18nMark(`Не найдено мозаик, за ${dateRange} !`),
          place: 'bc',
        })
      );
    } else {
      yield put(changeStartTime.request());
    }
  } catch (error) {
    yield put(fetchItems.failure(error));
  } finally {
    yield put(notifyActions.remove(messageId));
  }
}

const getMosaicImageUrls = (item: stacItemsTypes.STACItem) => {
  const itemImageAssets = Object.values(item).map(value => value.map(feature => feature.assets))[0];
  const imageUrls = itemImageAssets.map(asset =>
    Object.keys(asset).reduce((url: string, assetKey) => {
      if (assetKey.startsWith('image.tif.ms.3857') || assetKey.startsWith('image.tif.bt.3857')) {
        url = asset[assetKey].href;
      }
      return url;
    }, '')
  );
  const mosaicImageUrls = imageUrls.map(url => `${VTMS_URL}/tiles/{z}/{x}/{y}?url=${url}`);
  return mosaicImageUrls;
};

export function* toggleMosaicLayeFlow(action: ActionType<typeof fetchItems.success>) {
  const items: stacItemsTypes.STACItem[] = action.payload;
  const activeItemIndex: number = yield select(getActiveItemIndex);
  const mosaicLayers: MosaicLayers = yield select(getMosaicLayers);
  const selectedMosaicLayerId: string = yield select(getSelectedMosaicLayerId);
  const selectedMosaicLayer = mosaicLayers.find(layer => layer.id === selectedMosaicLayerId);
  const map = GeoportalMap.getInstance();

  if (items.length > 0) {
    const itemProperties = getNestedObjectValue(items[activeItemIndex], 'properties');
    const mosaicLayerUrls = getMosaicImageUrls(items[activeItemIndex]);
    yield call([map, 'toggleMosaicLayer'], selectedMosaicLayer, itemProperties, mosaicLayerUrls);
  }
}

export function* removeImageLayerFlow(action: ActionType<typeof clearItems>) {
  const layerId: string = action.payload;
  const map: GeoportalMap = GeoportalMap.getInstance();
  yield call([map, 'removeLayerById'], layerId, LAYER_GROUPS.mosaicLayers);
}

export function* setActiveItemFlow(action: ActionType<typeof fetchItems.success>) {
  try {
    const items: stacItemsTypes.STACItem[] = yield select(getItems);
    const startTime: string = yield select(getStartTime);

    if (items.length > 0) {
      const nearestIndex = findNearestIndex(
        startTime,
        items.map(item => new Date(Object.keys(item)[0]))
      );
      yield put(changeActiveItemIndex(nearestIndex));
    } else {
      yield put(changeActiveItemIndex(0));
    }
  } catch (error) {
    debug.error(error);
  }
}

export function* changeActiveItemIndexFlow(action: ActionType<typeof changeActiveItemIndex>) {
  try {
    const activeItemIndex: number = action.payload;
    const mosaicLayers: MosaicLayers = yield select(getMosaicLayers);
    const selectedMosaicLayerId: string = yield select(getSelectedMosaicLayerId);
    const selectedMosaicLayer = mosaicLayers.find(layer => layer.id === selectedMosaicLayerId);
    const items: stacItemsTypes.STACItem[] = yield select(getItems);
    if (items.length > 0) {
      const map = GeoportalMap.getInstance();
      if (selectedMosaicLayer?.id === 'MSUGS_A_EL') {
        const previousUrl = new URL(selectedMosaicLayer?.config.options.urls);
        previousUrl.searchParams.set(
          'datetime',
          new Date(Object.keys(items[activeItemIndex])[0]).toISOString().substring(0, 16)
        );
        yield call([map, 'updateMosaicLayer'], selectedMosaicLayer, [decodeURI(previousUrl.href)]);
      } else {
        const mosaicLayerUrls = getMosaicImageUrls(items[activeItemIndex]);
        yield call([map, 'updateMosaicLayer'], selectedMosaicLayer, mosaicLayerUrls);
      }
    }
    // yield put(changeStartTime(Object.keys(currentItem)[0].slice(16, 24)));
  } catch (error) {
    debug.error(error);
  }
}

export function* changeStartTimeFlow(action: ActionType<typeof changeStartTime.request>) {
  try {
    const activeItem: number = yield select(getActiveItemIndex);
    const items: stacItemsTypes.STACItem[] = yield select(getItems);
    if (items.length > 0) {
      const newStartTime: string = Object.keys(items[activeItem])[0].slice(16, 24);
      yield put(changeStartTime.success(newStartTime));
    }
  } catch (error) {
    debug.error(error);
  }
}

function* animateItemsFlow() {
  // const currentAnimateState: stacItemsTypes.AnimateState = yield select(getAnimateItems);
  // const allItems: stacItemsTypes.STACItem[] = currentAnimateState.animateImages;
  // const mosaicLayers: MosaicLayers = yield select(getMosaicLayers);
  // const selectedMosaicLayerId: string = yield select(getSelectedMosaicLayerId);
  // const selectedMosaicLayer = mosaicLayers.find(layer => layer.id === selectedMosaicLayerId);
  // const map = GeoportalMap.getInstance();
  // const items: stacItemsTypes.STACItem[] =
  //   currentAnimateState.direction === 'forward' ? allItems.reverse() : allItems;
  // while (true) {
  //   for (const item of items) {
  //     yield call(
  //       [map, 'updateMosaicLayer'],
  //       selectedMosaicLayer,
  //       selectedMosaicLayer?.config.options.url +
  //       new Date(Object.keys(item)[0]).toISOString().substring(0, 16)
  //     );
  //     // yield put(changeActiveItem({ index: items.indexOf(item), item: item }));
  //     yield delay(currentAnimateState.delay);
  //   }
  //   // yield put(changeActiveItem({ index: 0, item: {} }));
  // }
}

export function* animateSTACItemsFlow(action: ActionType<typeof animateItems>) {
  try {
    const animateSTACItemsState: stacItemsTypes.AnimateState = yield select(getAnimateItems);
    if (animateSTACItemsState.isAnimate) {
      yield call(animateItemsFlow);
    }
    // yield put(playArcticLayer());
  } catch (error) {
    debug.error(error);
  }
}

export default function* mapLayersRoot() {
  yield all([takeLatest(getType(changeActiveItemIndex), changeActiveItemIndexFlow)]);
  yield all([takeLatest(getType(animateItems), animateItemsFlow)]);
  yield all([takeLatest(getType(fetchItems.request), fetchItemsFlow)]);
  yield all([takeLatest(getType(fetchItems.success), toggleMosaicLayeFlow)]);
  yield all([takeLatest(getType(fetchDates.request), fetchItemsDatesFlow)]);
  yield all([takeLatest(getType(changeStartTime.request), changeStartTimeFlow)]);
  yield all([takeLatest(getType(clearItems), removeImageLayerFlow)]);
}
